@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.sebm-google-map-container {
    height: 300px;
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 70px;
}

.places-buttons .btn {
    margin-bottom: 30px
}

.space-70 {
    height: 70px;
    display: block;
}

.tim-row {
    margin-bottom: 20px;
}

.tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
}

.tim-typo .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}

.tim-row {
    padding-top: 50px;
}

.tim-row h3 {
    margin-top: 0;
}

@media (min-width: 992px) {

    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.fw-600 {
    font-weight: 600 !important;
}

.nav-link {
    cursor: pointer;
}


.sidebar .nav p {
    margin: 0;
    line-height: 30px;
    font-size: 14px;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
    color: rgba(125, 125, 131, .8);
    font-weight: 900;

}

.sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini,
.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse]~div>ul>li>a .sidebar-mini {
    text-transform: uppercase;
    width: 30px;
    margin-right: 15px;
    text-align: center;
    letter-spacing: 1px;
    position: relative;
    float: left;
    display: inherit;
    color: rgba(125, 125, 131, .8) !important;
}

.sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal,
.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
    margin: 0;
    position: relative;
    transform: translate(0);
    opacity: 1;
    white-space: nowrap;
    display: block;
    color: rgba(125, 125, 131, .8) !important;
    font-weight: 900;
}

.sidebar .nav li.active>a,
.sidebar .nav li.active>a i {
    color: #fff !important;
}

.sidebar[data-color=white] li.active>a {

    font-weight: 800 !important;

    box-shadow: unset !important;
    background: linear-gradient(90deg, #55c3b7 0, #5fd0a5 48%, #66da90 100%) !important;
    color: #fff !important;
}



.btn-primary {

    font-weight: 900 !important;
    text-transform: capitalize;

    background: linear-gradient(90deg, #55c3b7 0, #5fd0a5 48%, #66da90 100%) !important;
    border-radius: 8px !important;


}

.btn-secondary {
    font-weight: 800;
    color: #7d7d83 !important;
    background: #f4f4f5 !important;
    border-radius: 8px !important;
}

.card .card-header.card-header-icon .card-title {
    color: #474747 !important;
    text-transform: uppercase;
    font-weight: 900 !important;
}

.card [class*=card-header-] .card-icon,
.card [class*=card-header-] .card-text {
    border-radius: 3px;
    background-color: #e4f3f6;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
    color: #f05154 !important;
    background: transparent !important;
    background-color: #fcdcdc !important;


}

.card-two {
    border-radius: 3px;
    background-color: #e4f3f6;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
    background: #fcdcdc !important;
    color: #f05154;
}

.card-three {
    border-radius: 3px;
    background-color: #e4f3f6;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
    background: #ddf0f1 !important;
    color: #5fd0a5;
}

.card-four {
    border-radius: 3px;
    background-color: #e4f3f6;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
    background: #ddf0f1 !important;
    color: #5fd0a5;
}

.card-five {
    border-radius: 3px;
    background-color: #e4f3f6;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
    background: #ddf0f1 !important;
    color: #5fd0a5;
}

.table>thead>tr>th {
    border-bottom-width: 1px;
    font-size: 1rem !important;
    color: rgba(92, 102, 98, .8) !important;

    font-weight: 900 !important;
}

.table>tbody>tr>td {
    color: #5c6662;
    font-weight: 400 !important;
}



.text_primary {
    color: #5fd0a5 !important;
}

.ngx-pagination .current {

    background: linear-gradient(90deg, #55c3b7 0, #5fd0a5 48%, #66da90 100%) !important;
    color: #fff;
}

.navbar .navbar-brand {
    color: #7d7d83 !important;
    font-weight: 900 !important;
}

.navbar {


    box-shadow: unset !important;
}

.main-panel .main-content {
    margin-top: 70px;
    padding: 30px 15px;
    background-color: #f7f7f7 !important;
    min-height: calc(100vh - 123px);
}

body {
    background-color: #f8faf9 !important;
    color: #7d7d83 !important;

}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-size: unset !important;
}

.invalid-feedback {
    display: none;
    width: 100%;
    /* margin-top: -1.75rem; */
    font-size: .875em;
    color: #f44336;
    margin-top: -40px !important;
    margin-bottom: 10px !important;
    font-weight: 500;
}

.NgxEditor__Wrapper {
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 4px;
    height: 240px;
    margin-bottom: 30px;
}

.text-danger {
    color: #f44336 !important;
    font-size: .875em !important;
    font-weight: 500 !important;
}

.custome-margin .text-danger {
    margin-top: -10px !important;
}

.logo-text {
    color: #5fd0a5;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 22px;
}

.logo-text:hover {
    color: #5fd0a5;
}

.nav-item.active .nav-link p {
    color: #fff;

}